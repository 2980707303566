const keyDownArrowLeftRight = async params => {

  const {
    event,
    modifierKey,
    shiftKey,
    key,
    filteredFormattingKeyInfos,
    currentFrmtgKeyIdSetting,
    goSetCurrentFormattingKeyId,
  } = params

  if(modifierKey) return
  if(shiftKey) return
  event.preventDefault()

  const pageKeyIndex = Math.max(filteredFormattingKeyInfos.findIndex(({ id }) => id === currentFrmtgKeyIdSetting.value), 0) + (key === 'ArrowLeft' ? -1 : 1)
  const newFormattingKeyInfo = filteredFormattingKeyInfos[pageKeyIndex]

  if(newFormattingKeyInfo) {
    goSetCurrentFormattingKeyId({
      value: newFormattingKeyInfo.id,
    })
  }

}

export default keyDownArrowLeftRight
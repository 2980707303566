import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 110px;
  z-index: 5;
  user-select: none;
  background-color: rgb(0 0 0/.05);
  min-width: 0;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid white;
  color: rgb(0 0 0/.55);

  :hover {
    background-color: rgb(189 172 89/0.3);
  }

  .MuiSvgIcon-root {
    width: 18px;
  }
`

const DotNotesButton = ({
  onClick,
}) => {

  return (
    <Tooltip
      title={i18n("Add a “dot note”")}
    >
      <StyledIconButton
        onClick={onClick}
        color="primary"
        tabIndex={-1}
      >
        <AddIcon />
      </StyledIconButton>
    </Tooltip>
  )
}

export default memo(DotNotesButton)
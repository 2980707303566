import { memo } from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'

import useFormattingKey from '../../../hooks/useFormattingKey'
import useModalAnchor from '../../../hooks/useModalAnchor'

import OutlineKey from './OutlineKey'
import FormattingKey from '../shared/FormattingKey'
import OptionsPopover from '../../common/OptionsPopover'

const StyledFab = styled(Fab)`
  position: absolute;
  bottom: 14px;
  left: 5px;
  box-shadow: 1px 8px 40px rgb(0 0 0 / 30%);
  color: white;
  background-color: ${({ theme }) => theme.palette.grey[700]};

  ${({ $noneditableViewingMode }) => !$noneditableViewingMode ? `` : `
    left: 15px;
  `}

  :hover {
    background-color: ${({ theme }) => theme.palette.grey[900]};
  }

  ${({ $open, theme }) => !$open ? `` : `
    background-color: ${theme.palette.grey[900]};
  `}

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`

const StyledOptionsPopover = styled(OptionsPopover)`
  .MuiPopover-paper {
    margin-top: -5px;
    margin-left: -5px;
    max-height: calc(100vh - 65px);
    max-width: calc(100vw - 20px);
    min-height: 150px;
    min-width: 130px;
  }
`

const Container = styled.div`
  width: 240px;
  margin-left: 15px;
  position: relative;

  ${({ $printOrDownloadInfo }) => !$printOrDownloadInfo ? `` : `
    width: 100%;
    margin: 0;
    align-self: start;

    & > div {
      position: static;
      height: auto;
      max-height: none;
      border-radius: 0;
      border: none;
    }

    & > div > div {
      background: none;
    }
  `}

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    margin: 0;
    width: auto;
    display: inline-block;
  `}

`

const getBoxTop = ({ $noneditableViewingMode }) => $noneditableViewingMode ? `10px` : `60px`;
const Box = styled.div`
  position: absolute;
  top: ${getBoxTop};
  width: 100%;
  height: calc(100% - ${getBoxTop} - 10px);
  max-height: calc(100% - ${getBoxTop} - 10px);
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  box-sizing: border-box;
  overflow: auto;

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    position: relative;
    top: 0;
    height: auto;
    max-height: none;
  `}
`

const StyledFormattingKey = styled(FormattingKey)`
  max-width: none;
  flex: 1;
  padding: 15px;
`

const OutlineKeys = ({
  formattingKeyId,
  outlineVisibility,
  formattingKeyView,
  outlineKeysMinimized,
  printOrDownloadInfo,
  noneditableViewingMode,
  ...otherProps
}) => {

  const { hasLabels } = useFormattingKey(formattingKeyId)
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  if(noneditableViewingMode === `within-notes` || printOrDownloadInfo) {
    outlineVisibility = `SHOW`
    formattingKeyView = `NONE`
    outlineKeysMinimized = false
  }

  if(
    outlineVisibility !== `SHOW`
    && (
      formattingKeyView !== `FULL`
      || !hasLabels
    )
  ) return null

  if(outlineKeysMinimized && !printOrDownloadInfo) {
    return (
      <>

        <StyledFab
          onMouseDown={openModal}  // instead of onClick so that restored focus doesn't come back to this button
          $open={!!anchorEl}
          tabIndex={-1}
          size="small"
          $noneditableViewingMode={noneditableViewingMode}
        >
          <FormatListNumberedIcon />
        </StyledFab>

        <StyledOptionsPopover
          anchorEl={anchorEl}
          onClose={closeModal}
          forceAbove
          hideArrow
        >

          {outlineVisibility === `SHOW` &&
            <OutlineKey
              {...otherProps}
            />
          }

          {formattingKeyView === `FULL` &&
            <StyledFormattingKey
              id={formattingKeyId}
              className="outline-formatting-key"
              hideExplanation
            />
          }

        </StyledOptionsPopover>

      </>
    )
  }

  return (
    <Container
      $printOrDownloadInfo={printOrDownloadInfo}
      $noneditableViewingMode={noneditableViewingMode}
    >
      <Box $noneditableViewingMode={noneditableViewingMode}>

        {outlineVisibility === `SHOW` &&
          <OutlineKey
            printOrDownloadInfo={printOrDownloadInfo}
            noneditableViewingMode={noneditableViewingMode}
            {...otherProps}
          />
        }

        {formattingKeyView === `FULL` &&
          <StyledFormattingKey
            id={formattingKeyId}
            className="outline-formatting-key"
            hideExplanation
          />
        }

      </Box>
    </Container>
  )
}

export default memo(OutlineKeys)
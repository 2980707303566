import { memo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { useMeasure } from 'react-use'

import { IS_EMBED } from '../../../utils/constants'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useVersesPieces from '../../../hooks/useVersesPieces'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useMarkupShortcuts from '../../../hooks/useMarkupShortcuts'
import { getEmbedMode } from '../../../graphql/links/embedLink'
import { preventDefaultEvent } from '../../../utils/misc'
import { getWordObjFromEl, wordIsSameOrBefore } from '../../../hooks/useTextSelectionMarkup'

import Loading from '../../common/Loading'
import FadedLoading from '../../common/FadedLoading'
import TextContent from '../../common/TextContent'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import MarkupContentTextField from './MarkupContentTextField'

const PADDING_TOP = 60
const EMBED_PADDING_BOTTOM = 60

const OuterContainer = styled.div`
  flex: 1;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: 1;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  z-index: 0;
  outline: none;
`

const Content = styled.div`
  padding: ${PADDING_TOP}px 0 ${() => [ `frozen`, `edit` ].includes(getEmbedMode()) ? `${EMBED_PADDING_BOTTOM}px` : `calc(100vh - 200px)`};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const StyledTextContentMarkupContainer = styled(TextContentMarkupContainer)`
  /* position: relative; */
  font-size: ${({ $textFontSize }) => $textFontSize}px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};

  ${({ $showVsNums }) => $showVsNums ? `` : `
    .TextContent-tag-v,
    .TextContent-tag-vp {
      display: none;
    }
  `}

  ${({ $inEditingMode }) => !$inEditingMode ? `` : `

    .TextContent-tag-w:hover {
      cursor: text;
      opacity: 1;
    }

    user-select: none;
    .TextContent-verse {
      user-select: text;
    }

  `}

`

const MarkupContent = ({
  module,
  projectId,
  isRTL,
  textFontSize,
  lineSpacing,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  showContextSetting,
  greekPunctuationSetting,
  paragraphFormattingSetting,
  lastScrollPositionSetting,
  proceedingContextSetting,
  followingContextSetting,
  currentFrmtgKeyIdSetting,
  filteredFormattingKeyInfos,
  inEditingMode,
  noneditableViewingMode,
  goSetPopperInfo,
  goUndo,
  goRedo,
  goPrintOrDownload,
  // embedFullScreen,
  markup,
  markupLoading,
  setHeight,
}) => {

  const { modulePassages } = module
  const { fromLoc, toLoc, info={} } = modulePassages[0] || {}
  const { versionId } = info
  const { bookId, chapter: startChapter } = getRefFromLoc(fromLoc || ``)
  const { chapter: endChapter } = getRefFromLoc(toLoc || fromLoc || ``)

  const [ pieces, loading ] = useVersesPieces({
    inlineMarkersOnly: paragraphFormattingSetting.value === `NONE`,
    hideHeadings: paragraphFormattingSetting.value === `PARAGRAPHS-ONLY`,
    ...modulePassages[0],
    ...modulePassages[0].info,
  })

  let [ contentRef, { height } ] = useMeasure()
  const containerRef = useRef(null)

  // const ready = chaptersReady && contentWidth > 0
  // loading

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getModule = useInstanceValue(module)

  // const [ goSetLastScrollPositionSetting ] = useGoSetModuleSetting({
  //   moduleSetting: lastScrollPositionSetting,
  //   projectId,
  // })

  const [ goSetCurrentFormattingKeyId ] = useGoSetModuleSetting({
    moduleSetting: currentFrmtgKeyIdSetting,
    projectId,
  })

  useMarkupShortcuts({
    getModule,
    goUpdateModule,
    goUndo,
    goRedo,
    goPrintOrDownload,
    noneditableViewingMode,
    containerRef,
    filteredFormattingKeyInfos,
    currentFrmtgKeyIdSetting,
    goSetCurrentFormattingKeyId,
  })

  const onDoubleClick = useCallback(
    event => {
      const { target, clientX, clientY } = event

      // get the relevant word
      const bottomLevelChildNodes = [ ...target.querySelectorAll(`.text-content-word[data-word-loc]`), target.closest(`.text-content-word[data-word-loc]`) ].filter(Boolean)
      const { el: effectiveClickedWordEl } = bottomLevelChildNodes.reduce(
        (closestObj, el) => {
          const { x, y, width, height } = el.getBoundingClientRect()
          const distance = Math.min(
            Math.abs(x - clientX),
            Math.abs(x + width - clientX)
          )
          if(
            y < clientY
            && y + height > clientY
            && distance < 30
            && (
              !closestObj
              || distance < closestObj.distance
            )
          ) {
            return {
              el,
              distance,
            }
          }
          return closestObj
        },
        null,
      ) || {}

      if(effectiveClickedWordEl) {
        // select the appropriate text
        const word = getWordObjFromEl(effectiveClickedWordEl)
        const matchingMarkup = markup.filter(({ start, end }) => (
          wordIsSameOrBefore(start, word)
          && wordIsSameOrBefore(word, end)
        ))
        const calcDist = ({ start, end }) => (parseInt(end.loc, 10) - parseInt(start.loc, 10)) * 100 + (end.wordNumberInVerse - start.wordNumberInVerse)
        matchingMarkup.sort((a,b) => calcDist(a) < calcDist(b) ? -1 : 1)
        const containerEl = target.closest(`.MarkupContent-StyledTextContentMarkupContainer`)
        if(matchingMarkup[0] && containerEl) {
          const { start, end } = matchingMarkup[0]
          const startWordEl = containerEl.querySelector(`.text-content-word[data-word-loc="${start.loc}:${start.wordNumberInVerse}"]`)
          const endWordEl = containerEl.querySelector(`.text-content-word[data-word-loc="${end.loc}:${end.wordNumberInVerse}"]`)
          if(startWordEl && endWordEl) {
            preventDefaultEvent(event)
            const range = document.createRange()
            const selection = window.getSelection()
            range.setStart(startWordEl, 0)
            range.setEnd(endWordEl, endWordEl.childNodes.length)
            selection.empty()
            selection.addRange(range)
          }
        }
      }
    },
    [ markup ],
  )

  useEffectAsync(
    () => {
      // if(!ready) return
      if(noneditableViewingMode) return
      if(IS_EMBED && getEmbedMode() !== `prep`) return
      // TODO: scroll to right place
    },
    [ loading ],
  )

  useEffectAsync(
    () => {
      setHeight && setHeight(height + PADDING_TOP + EMBED_PADDING_BOTTOM)
    },
    [ height ],
  )

  // const currentLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  // const newLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  // const [ setUpdateLastScrollPositionTimeout ] = useSetTimeout()
  // const updateLastScrollPosition = () => {
  //   if(!equalObjs(currentLastScrollPositionSettingValue.current, newLastScrollPositionSettingValue.current)) {
  //     goSetLastScrollPositionSetting({
  //       value: newLastScrollPositionSettingValue.current,
  //     })
  //     currentLastScrollPositionSettingValue.current = newLastScrollPositionSettingValue.current
  //   }

  // }

  // const onScroll = useCallback(
  //   () => {
  //     // TODO: throttle
  //     if(!isScrolling && containerRef.current && !noneditableViewingMode) {
  //       const rowEls = containerRef.current.querySelectorAll(`[data-index]`)
  //       const containerTop = containerRef.current.getBoundingClientRect().top
  //       for(let idx=0; idx<rowEls.length; idx++) {
  //         const { top } = rowEls[idx].getBoundingClientRect()
  //         const offset = (top - containerTop) * -1
  //         if(offset < 0 || idx === rowEls.length - 1) {
  //           newLastScrollPositionSettingValue.current = {
  //             align: `start`,
  //             index: parseInt(rowEls[idx].getAttribute(`data-index`), 10),
  //             offset,
  //           }
  //           setUpdateLastScrollPositionTimeout(updateLastScrollPosition, 5000)
  //           break
  //         }
  //       }
  //     }
  //   },
  //   [],
  // )

  // useEffect(() => updateLastScrollPosition, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OuterContainer>

      <Container
        className="MarkupContent-Container"
        ref={containerRef}
        tabIndex={0}
        data-module-id={module.id}
      >

        <Content ref={contentRef}>

          {!!showContextSetting.value &&
            <MarkupContentTextField
              moduleSetting={proceedingContextSetting}
              projectId={projectId}
              disabled={!inEditingMode}
              label={i18n("Proceeding Context Summary")}
            />
          }

          <StyledTextContentMarkupContainer
            $textFontSize={textFontSize}
            $lineSpacing={lineSpacing}
            $showVsNums={showVsNumsSetting.value}
            $inEditingMode={inEditingMode}
            isRTL={isRTL}
            markup={markup}
            onDoubleClick={onDoubleClick}
            className="MarkupContent-StyledTextContentMarkupContainer"
          >

            <TextContent
              showCfs={showCfsSetting.value}
              showNotes={showNotesSetting.value}
              showCantillation={showCantillationSetting.value}
              showHebrewVowels={showHebrewVowelsSetting.value}
              showGreekAccents={showGreekAccentsSetting.value}
              greekPunctuation={greekPunctuationSetting.value}
              pieces={pieces}
              versionId={versionId}
              bookId={bookId}
              startChapter={startChapter}
              // wordClassName="markup-text-word"
              showChapterNumbers={startChapter !== endChapter}
              goSetPopperInfo={goSetPopperInfo}
              ignoreClick={inEditingMode ? `word` : false}
            />

          </StyledTextContentMarkupContainer>

          {!!showContextSetting.value &&
            <MarkupContentTextField
              moduleSetting={followingContextSetting}
              projectId={projectId}
              disabled={!inEditingMode}
              label={i18n("Following Context Summary")}
            />
          }

        </Content>

      </Container>

      {loading && <Loading />}
      {!loading && markupLoading && <FadedLoading />}

    </OuterContainer>
  )
}

export default memo(MarkupContent)
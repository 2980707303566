import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
	background-image: url('${({ theme, color }) => (
    `data:image/svg+xml;utf8,
      <svg viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <polyline fill="none" stroke="${encodeURIComponent(theme.palette.markupColors[color].line)}" stroke-linecap="round" stroke-linejoin="round" stroke-width=".2em" points="1.526 1.523 10 1.523" />
      </svg>
    `.replace(/\s*\n\s*/g, ' ')
  )}');
	background-position: left bottom;
	background-size: .6em .12em;
	background-repeat: repeat-x;
  padding-bottom: .1em;
  z-index: -5;
`

const DashUnderline = ({
  color,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    color={color}
  />
)


export default memo(DashUnderline)


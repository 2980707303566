import { gql } from '@apollo/client'
import moduleDot_fields from '../fragments/moduleDot_fields'

const moduleDots = gql`
  query moduleDots($moduleId: ID!, $modulePieceId: String) {
    moduleDots(moduleId: $moduleId, modulePieceId: $modulePieceId) {
      ${moduleDot_fields}
    }
  }
`

export default moduleDots
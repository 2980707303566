import { useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'
import { cloneObj } from '../utils/misc'

import moduleMarkupQuery from '../graphql/queries/moduleMarkup'
import moduleMarkupsQuery from '../graphql/queries/moduleMarkups'
import updateModuleMarkupMutation from '../graphql/mutations/updateModuleMarkup'
import deleteModuleMarkupMutation from '../graphql/mutations/deleteModuleMarkup'

export const getDefaultModuleMarkup = () => ({
  __typename: `ModuleMarkup`,
  container: `none`,
  positionInfo: null,
  type: 'BASE-HIGHLIGHT',
  color: 'YELLOW',
})

const useGoUpdateModuleMarkup = ({
  moduleMarkup,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  moduleId = moduleId || moduleMarkup.moduleId

  const client = useApolloClient()

  const [ updateModuleMarkup, updateModuleMarkupResult ] = useMutation(updateModuleMarkupMutation)
  const [ deleteModuleMarkup, deleteModuleMarkupResult ] = useMutation(deleteModuleMarkupMutation)

  const onUpdate = useCallback(
    ({ newData }) => {
      // update moduleMarkups query

      const updateQuery = ({ container, doRemove }={}) => {

        const queryParams = {
          query: moduleMarkupsQuery,
          variables: {
            moduleId,
            container,
          },
        }

        const { moduleMarkups } = client.readQuery(queryParams) || {}

        if(moduleMarkups) {
          if(!doRemove && !moduleMarkups.some(({ id }) => id === newData.id)) {
            client.writeQuery({
              ...queryParams,
              data: {
                moduleMarkups: [ ...cloneObj(moduleMarkups), newData ].sort((a,b) => a.id < b.id ? -1 : 1),
              },
            })
          } else if(doRemove && moduleMarkups.some(({ id }) => id === newData.id)) {
            client.writeQuery({
              ...queryParams,
              data: {
                moduleMarkups: cloneObj(moduleMarkups).filter(({ id }) => id === moduleMarkup.id),
              },
            })
          }
        }

      }

      if(moduleMarkup) {
        if(moduleMarkup.container !== newData.container) {
          // remove it from the old container
          updateQuery({
            container: moduleMarkup.container,
            doRemove: true,
          })
        }
      } else {
        // put it in the query without container
        updateQuery()
      }

      // add it to the new container
      updateQuery({ container: newData.container })

    },
    [ moduleMarkup, moduleId, client ],
  )

  const goUpdate = useGoUpdateTable({
    currentData: moduleMarkup || getDefaultModuleMarkup(),
    updateFunc: updateModuleMarkup,
    updateResult: updateModuleMarkupResult,
    deleteFunc: deleteModuleMarkup,
    deleteResult: deleteModuleMarkupResult,
    projectId,
    moduleId,
    undoRedoStack,
    onUpdate,
  })

  const toReturn = useMemo(
    () => {
      if(!moduleMarkup) {
        return [
          initData => {
            // Create a new moduleMarkup

            const newData = goUpdate[0](
              {
                ...initData,
                moduleId,
              },
              getDefaultModuleMarkup(),
            )

            client.writeQuery({
              query: moduleMarkupQuery,
              data: {
                moduleMarkup: newData,
              },
              variables: {
                id: newData.id,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ moduleMarkup, moduleId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModuleMarkup
import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { i18n } from 'inline-i18n'

import { cloneObj, reorder } from '../../../utils/misc'

import LensSettingsLensListLine from './LensSettingsLensListLine'

const Container = styled.div`
  min-height: 0;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Contents = styled.div`
  min-height: 0;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1px 0;
`

const Lines = styled.div`
`

const None = styled.div`
  padding: 50px 40px;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const LensSettingsLensList = ({
  formattingKeyInfos,
  filteredFormattingKeyInfos,
  goSetFormattingKeyInfos,
  setFormattingKeyIdInEdit,
}) => {

  const onDragEnd = useCallback(
    result => {
      if(!result.destination) return
      if(result.destination.index === result.source.index) return

      const newFormattingKeyInfos = reorder({
        list: filteredFormattingKeyInfos,
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })

      formattingKeyInfos.forEach(formattingKeyInfo => {
        if(!newFormattingKeyInfos.some(({ id }) => id === formattingKeyInfo.id)) {
          newFormattingKeyInfos.push(formattingKeyInfo)
        }
      })

      goSetFormattingKeyInfos({ value: newFormattingKeyInfos })
    },
    [ filteredFormattingKeyInfos, goSetFormattingKeyInfos, formattingKeyInfos ],
  )

  if(filteredFormattingKeyInfos.length === 0) {
    return (
      <None>
        {i18n("You do not have any lenses set up.")}
      </None>
    )
  }

  return (
    <Container>

      <Contents>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Lines>
                    {filteredFormattingKeyInfos.map(({ id }, index) => (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={index}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <LensSettingsLensListLine
                              formattingKeyId={id}
                              onEdit={() => setFormattingKeyIdInEdit(id)}
                              onRemove={() => {
                                goSetFormattingKeyInfos({
                                  value: cloneObj(formattingKeyInfos).filter(formattingKeyInfo => formattingKeyInfo.id !== id),
                                })
                              }}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Lines>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

      </Contents>

      {/* <CustomSnackbar {...snackbarInfo} /> */}

    </Container>
  )
}

export default memo(LensSettingsLensList)
import { memo, useCallback, useState } from 'react'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { i18n } from 'inline-i18n'

import { preventDefaultEvent, cloneObj } from '../../utils/misc'
import { formattingTypes, formattingColors, formattingComponentByType, getHasLabels } from '../../utils/formatting'
import useFormattingKey from '../../hooks/useFormattingKey'

const defaultFormattingProps = {
  top: 0,
  left: 0,
  width: 16,
  height: 18,
  style: {
    position: `relative`,
    zIndex: 1,
    fontSize: 13,
  },
}

const formattingPropsByComponentType = {
  "NORMAL-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 23,
  },
  "ZIG-ZAG-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 23,
  },
  "DASH-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 22,
  },
  "DOTTED-UNDERLINE": {
    ...cloneObj(defaultFormattingProps),
    height: 22,
  },
  "ARROW-HIGHLIGHT-DOUBLE": {
    ...cloneObj(defaultFormattingProps),
    width: 24,
  },
  "CIRCLE": {
    ...cloneObj(defaultFormattingProps),
    width: 25,
    height: 21,
    left: 2,
    top: 1,
  },
}

;[ ...Object.values(formattingPropsByComponentType), defaultFormattingProps ].forEach(props => {
  props.style.width = props.width
  props.style.height = props.height
})

const defaultBottomNavProps = {
  top: 0,
  left: 0,
  width: 12,
  height: 13,
  style: {
    position: `relative`,
    zIndex: 1,
    fontSize: 10,
  },
  darkGreyInsteadOfGrey: true,
}

const bottomNavPropsByComponentType = {
  "NORMAL-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 17,
  },
  "ZIG-ZAG-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 16,
  },
  "DASH-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 15,
  },
  "DOTTED-UNDERLINE": {
    ...cloneObj(defaultBottomNavProps),
    height: 15,
  },
  "ARROW-HIGHLIGHT-DOUBLE": {
    ...cloneObj(defaultBottomNavProps),
    width: 18,
  },
  "CIRCLE": {
    ...cloneObj(defaultFormattingProps),
    width: 18,
    height: 15,
    left: 2,
  },
}

;[ ...Object.values(bottomNavPropsByComponentType), defaultBottomNavProps ].forEach(props => {
  props.style.width = props.width
  props.style.height = props.height
})

const FormattingKeyName = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 10px;
  padding: 2px 0 7px;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ForMoreOptions = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 10px;
  padding: 7px 0 3px;
  max-width: 160px;
  line-height: 1.2;
  text-align: center;
`

const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;
`

const Page = styled.div`
  width: 184px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  gap: 8px;
`

const FormattingMenuItemContainer = styled.div`
  position: relative;
`

const FormattingMenuItem = styled(MenuItem)`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 5px;
  border: 1px solid ${({ $selected }) => $selected ? `white` : `rgb(255 255 255/.05)`};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .2s ease-in-out;
`

const Name = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
  text-align: center;
  font-size: 8px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
`

const T = styled.div`
  z-index: 2;
  position: relative;
  color: black;
`

const StyledBottomNavigation = styled(BottomNavigation)`
  margin: 5px 5px 0;
  background-color: transparent;
  flex-wrap: wrap;
  max-width: 200px;
  height: auto;
`

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  height: 30px;
  padding: 0 !important;
  transition: transform .2s ease-in-out;
  font-size: 13.4px;
  overflow: hidden;

  :hover {
    transform: translateY(-3px);

    &.Mui-selected {
      transform: none;
      cursor: default;
    }
  }
`

const ComponentContainer = styled.div`
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavIcon = styled.div`
  position: relative;

  & > div::before {
    background: none;
  }
`

let lastPageIdx = 0

const FormattingMenuList = ({
  toggleMarkup,
  existingMarkupDetailsByType,
  partialWordSelection,
  formattingKeyId,
  inNotes,
  ...otherProps
}) => {

  const isSingleWord = false  // TODO

  const [ pageIdx, setPageIdx ] = useState(lastPageIdx)
  lastPageIdx = pageIdx

  const formattingKey = useFormattingKey(formattingKeyId)

  const onChangePage = useCallback(
    (event, newValue) => {
      event.preventDefault()
      setPageIdx(newValue)
    },
    [],
  )

  const pages = formattingKey.type === `ONE-COLOR-PER-STYLE` ? [`ALL`] : Object.keys(formattingKey.labelsByStyles)

  const effectivePageIdx = pageIdx < pages.length ? pageIdx : 0
  const currentPage = pages[effectivePageIdx]
  const typeForPage = formattingKey.type === `ONE-COLOR-PER-STYLE` ? null : pages[effectivePageIdx]
  const currentPageHasLabels = getHasLabels({
    labelsByStyles: (
    currentPage === `ALL`
      ? formattingKey.labelsByStyles
      : { ...formattingKey.labelsByStyles[currentPage] }
    )
  })
  const ComponentForPage = formattingComponentByType[typeForPage]

  return (
    <StyledMenuList {...otherProps}>

      <FormattingKeyName>
        {formattingKey.name}
      </FormattingKeyName>

      <Page>
        {(currentPage === `ALL` ? formattingTypes : formattingColors).map((typeOrColor, idx) => {
          const Component = ComponentForPage || formattingComponentByType[typeOrColor]
          const type = typeForPage || typeOrColor
          const labelsByColor = formattingKey.labelsByStyles[type] || {}
          const color = (
            (
              formattingKey.type === `ONE-COLOR-PER-STYLE`
              && Object.keys(labelsByColor)[0]
            )
            || formattingColors[idx]
            || `YELLOW`
          )

          if(currentPageHasLabels && !labelsByColor[color]) return null

          return (
            <FormattingMenuItemContainer key={idx}>
              <FormattingMenuItem
                key={typeOrColor}
                onMouseDown={preventDefaultEvent}
                onClick={toggleMarkup({ type, color })}
                tabIndex={-1}
                $selected={(existingMarkupDetailsByType[type] || {}).color === color}
                disabled={isSingleWord}
              >
                <Name>{labelsByColor[color] || ``}</Name>
                <T>T</T>
                <ComponentContainer>
                  <Component
                    color={color}
                    {...(formattingPropsByComponentType[type] || defaultFormattingProps)}
                  />
                </ComponentContainer>
              </FormattingMenuItem>
            </FormattingMenuItemContainer>
          )
        })}
      </Page>

      {pages.length > 1 &&
        <StyledBottomNavigation
          value={effectivePageIdx}
          onMouseDown={preventDefaultEvent}
          onChange={onChangePage}
        >
          {pages.map((type, idx) => {
            const Component = formattingComponentByType[type]
          
            return (
              <StyledBottomNavigationAction
                key={type}
                icon={
                  <NavIcon>
                    <T>T</T>
                    <ComponentContainer>
                      <Component
                        color={idx === effectivePageIdx ? `WHITE` : `GREY`}
                        {...(bottomNavPropsByComponentType[type] || defaultBottomNavProps)}
                      />
                    </ComponentContainer>
                  </NavIcon>
                }
              />
            )
          })}
        </StyledBottomNavigation>
      }

      {formattingKey.id === `basic-highlighting` &&
        <ForMoreOptions>
          {i18n("For more options or to add a key, change the “Formatting Set” under this {{module_or_passage}}’s settings.", {
            module_or_passage: (
              inNotes
                ? i18n("passage")
                : i18n("module")
            )
          })}
        </ForMoreOptions>
      }

      {/* IMPORTANT: If I add things here, I need to update `verticalSpaceNeeded` in PassageInNotesContent */}

    </StyledMenuList>
  )
}


export default memo(FormattingMenuList)
import { useCallback, useMemo } from "react"

import useLayoutEffectAsync from "./useLayoutEffectAsync"
import useInstanceValue from "./useInstanceValue"

const useMirrorRefs = (refs=[]) => {

  if(!(refs instanceof Array)) {
    refs = [ refs ].filter(Boolean)
  }
  const getRefs = useInstanceValue(refs)

  const updateRefs = useCallback(
    () => [
      getRefs().forEach(r => {
        if(typeof r === `function`) {
          r(ref.current)
        } else {
          r.current = ref.current
        }
      })
    ],
    [ getRefs ],
  )

  const ref = useMemo(
    () => ({
      set current(ref) {
        this.crnt = ref
        updateRefs()
      },
      get current() {
        return this.crnt
      },
    }),
    [],
  )

  useLayoutEffectAsync(updateRefs, refs)

  return ref
}

export default useMirrorRefs

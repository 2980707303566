import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { i18n } from 'inline-i18n'

import { cloneObj, reorder } from '../../utils/misc'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const DeleteDroppableContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  background: ${({ $isDraggingOver }) => $isDraggingOver ? `red` : `grey`};
`

const DeleteDropZone = styled.div`
  visibility: ${({ $dragging }) => $dragging ? `visible` : `hidden`};
  position: relative;
  margin-top: 10px;
  z-index: 1000;
  width: 100%;
  height: ${({ $dragging, $height }) => $dragging ? $height : 0}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px ${({ $isDraggingOver, theme }) => $isDraggingOver ? `solid ${theme.palette.tertiary.main}` : `dashed ${theme.palette.grey[300]}`};
  background: ${({ $isDraggingOver, theme }) => $isDraggingOver ? theme.palette.tertiary.main : `white`};
  color: ${({ $isDraggingOver, theme }) => $isDraggingOver ? `white` : theme.palette.grey[500]};
  box-shadow: 1px 1px 10px white, -1px 1px 10px white, -1px -1px 10px white, 1px -1px 10px white;
  font-size: 15px;
`

const Hidden = styled.div`
  display: none;
`

const ReorderableList = ({
  list,
  Line,
  onReorder,
  idKey="id",
  height=100,
  showDeleteDropZone,
  ...otherProps
}) => {

  const [ dragging, setDragging ] = useState(false)
  const onDragStart = useCallback(() => setDragging(true), [])

  const onDragEnd = useCallback(
    result => {
      setDragging(false)

      if(!result.destination) return
      if(
        result.destination.droppableId !== `delete`
        && result.destination.index === result.source.index
      ) return

      let newList

      if(result.destination.droppableId === `delete`) {
        newList = cloneObj(list)
        newList.splice(result.source.index, 1)
      } else {
        newList = reorder({
          list,
          startIndex: result.source.index,
          endIndex: result.destination.index,
        })
      }

      onReorder && onReorder(newList)
    },
    [ onReorder, list ],
  )

  if(list.length === 0) return null

  return (
    <Container {...otherProps}>

      <DragDropContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {list.map((item, index) => (
                <Draggable
                  key={item[idKey]}
                  draggableId={item[idKey]}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Line
                        {...item}
                        index={index}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {!!showDeleteDropZone &&
          <DeleteDroppableContainer className="ReorderableList-DeleteDroppableContainer">
            <Droppable droppableId="delete">
              {(provided, { isDraggingOver }) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <DeleteDropZone
                    $dragging={dragging}
                    $height={height}
                    $isDraggingOver={isDraggingOver}
                  >
                    {i18n("Drop here to delete")}
                  </DeleteDropZone>
                  <Hidden>
                    {provided.placeholder}
                  </Hidden>
                </div>
              )}
            </Droppable>
          </DeleteDroppableContainer>
        }
      </DragDropContext>

    </Container>
  )
}

export default memo(ReorderableList)
import { memo, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"

import { getPassageTextSize, getPassageLineSpacing, getOrigVersionInfo, cloneObj, equalObjs } from '../../../utils/misc'
import useGoUndo from '../../../hooks/useGoUndo'
import useMarkupSettings from '../../../hooks/useMarkupSettings'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useVersionInfo from '../../../hooks/useVersionInfo'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useDataQuery from '../../../hooks/useDataQuery'
import useMirrorRefs from '../../../hooks/useMirrorRefs'
import selectText from './selection-utils/selectText'
import { moduleMarkupOnActiveLens } from '../../../hooks/useTextSelectionMarkup'

import ModuleContainer from '../shared/ModuleContainer'
import NewModuleHeadingAndPassageChooser from '../shared/NewModuleHeadingAndPassageChooser'
// import NewToMarkup from './NewToMarkup'
import ModuleHeaderBackground from '../shared/ModuleHeaderBackground'
import MarkupOptionsChangePassage from './MarkupOptionsChangePassage'
import MarkupSettings from './MarkupSettings'
import ModuleTitleButton from '../shared/ModuleTitleButton'
import EditingButton from '../shared/EditingButton'
import MarkupContent from './MarkupContent'
import MarkupKey from './MarkupKey'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'
import DotNotesButton from '../shared/DotNotesButton'

import moduleMarkupsQuery from '../../../graphql/queries/moduleMarkups'

const noop = ()=>{}

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 0;

  ${({ $noneditableViewingMode }) => ![ `shared-page`, `sketch`].includes($noneditableViewingMode) ? `` : `
    padding: 0 15px;

    @media (max-width:600px) {
      padding: 0 10px;
    }
  `}

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    justify-content: flex-start;
  `}
`

const Markup = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  noneditableViewingMode,
  printOrDownloadInfo,
  goPrintOrDownload,
  // goPrintEncompassingNotesTab,  // not needed, since MarkupContent (which has the shortcut key items) is not rendered
  goSketch,
  onLoad,
  setHeight,
  embedFullScreen,
  ...otherProps
}) => {

  const { id, modulePassages } = module
  const { fromLoc, info={} } = modulePassages[0] || {}
  const { bookId } = getRefFromLoc(fromLoc || ``)
  const { versionId } = info

  const [ measureRef, { width: moduleWidth } ] = useMeasure()
  const ref = useMirrorRefs(measureRef)
  const minimized = moduleWidth < 600  // TODO: base this on formattingKeyView as well

  const inEditingMode = module.inEditingMode && !noneditableViewingMode

  const origVersionInfo = useEqualObjsMemo(getOrigVersionInfo())
  const { version=origVersionInfo } = useVersionInfo(versionId)

  const { moduleMarkups=[], loading: loadingModuleMarkups } = useDataQuery({
    moduleMarkupsQuery,
    variables: {
      moduleId: id,
    },
  })

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      goPrintOrDownload({
        module,
        projectId,
        ...params,
      })
    },
    [ goPrintOrDownload, module, projectId ],
  )

  useEffectAsync(
    () => {
      // TODO: not sure what this is for
      if(onLoad) {
        onLoad()
      }
    },
    [],
  )

  const { formattingKeysById } = useFormattingKeys()
  const markupSettings = useMarkupSettings({ moduleId: id })
  const {
    textSizesSetting,
    lineSpacingsSetting,
    formattingKeyInfosSetting,
    currentFrmtgKeyIdSetting,
    lensesSetting,
    moduleSettingsLoading,
    showContextSetting,
  } = markupSettings

  const filteredFormattingKeyInfos = useEqualObjsMemo(formattingKeyInfosSetting.value.filter(({ id }) => formattingKeysById[id]))

  const [ goSetFormattingKeyInfos ] = useGoSetModuleSetting({
    moduleSetting: formattingKeyInfosSetting,
    projectId,
  })

  const [ goSetCurrentFormattingKeyId ] = useGoSetModuleSetting({
    moduleSetting: currentFrmtgKeyIdSetting,
    projectId,
  })

  const [ goSetShowContext ] = useGoSetModuleSetting({
    moduleSetting: showContextSetting,
    projectId,
  })

  const [ displayedTextSizes, setDisplayedTextSizes ] = useState(textSizesSetting.value)
  const [ displayedLineSpacings, setDisplayedLineSpacings ] = useState(lineSpacingsSetting.value)
  const [ selectedColor, setSelectedColor ] = useState(`ALL`)

  const formattingKeyInfo = filteredFormattingKeyInfos.find(({ id }) => id === currentFrmtgKeyIdSetting.value) || filteredFormattingKeyInfos[0]
  const formattingKeyInfoId = (formattingKeyInfo || {}).id

  const markup = useMemo(
    () => (
      moduleMarkups
        .filter(
          moduleMarkupOnActiveLens({
            lenses: lensesSetting.value,
            formattingKeyInfoId,
            selectedColor,
          })
        )
        .map(({ type, color, positionInfo }) => ({
          ...positionInfo,
          type,
          color,
        }))
    ),
    [ moduleMarkups, formattingKeyInfoId, selectedColor, lensesSetting.value ],
  )

  const prepAction = useCallback(
    async ({ items=[] }) => {
      for(let item of items) {
        if(/ModuleMarkup$/.test(item.action)) {
          const data = {
            ...(item.data || {}),
            ...(item.initData || {}),
            ...(item.updateObj || {}),
          }
          const { color, container } = data
          if(container !== `formattingKeyId:${formattingKeyInfoId}`) {
            const formattingKeyInfo = filteredFormattingKeyInfos.find(({ id }) => `formattingKeyId:${id}` === container)
            if(formattingKeyInfo) {
              goSetCurrentFormattingKeyId({
                value: formattingKeyInfo.id,
              })
              setSelectedColor(`ALL`)
              break
            }
          } else if(![ color, `ALL` ].includes(selectedColor)) {
            setSelectedColor(`ALL`)
            break
          }
        } else if(/ModuleSetting$/.test(item.action)) {
          if(
            /ing-context$/.test(item.data.id)
            && !showContextSetting.value
          ) {
            goSetShowContext({ value: true })
            await new Promise(resolve => setTimeout(resolve, 50))
            break
          } else if(/:formatting-key-infos$/.test(item.data.id)) {
            const lensUpdatesById = {}
            item.updateObj.value.forEach(lens => {
              lensUpdatesById[lens.id] = lens
            })
            if(item.data.value.some(lens => {
              if(
                !equalObjs(lens, lensUpdatesById[lens.id])
                && lens.id !== formattingKeyInfoId
              ) {
                goSetCurrentFormattingKeyId({
                  value: lens.id,
                })
                setSelectedColor(`ALL`)
                return true
              }
              return false
            })) break
          }
        }
      }
    },
    [ filteredFormattingKeyInfos, formattingKeyInfoId, selectedColor, showContextSetting.value, goSetCurrentFormattingKeyId, goSetShowContext ],
  )

  const { goUndo, goRedo, undoComponent } = useGoUndo({
    moduleId: id,
    projectId,
    moduleContainerRef: ref,
    prepAction,
  })

  const updateFormattingKeyInfo = useCallback(
    newFormattingKeyInfo => {
      const newFormattingKeyInfos = cloneObj(formattingKeyInfosSetting.value)
      const index = newFormattingKeyInfos.findIndex(({ id }) => id === formattingKeyInfoId)
      if(index !== -1) {
        newFormattingKeyInfos[index] = newFormattingKeyInfo
      }
      goSetFormattingKeyInfos({ value: newFormattingKeyInfos })
    },
    [ formattingKeyInfosSetting.value, goSetFormattingKeyInfos, formattingKeyInfoId ],
  )

  const onClick = useCallback(
    ({ target, currentTarget }) => {
      if(target && target.closest && target.closest(`input[type="text"], textarea, [contenteditable]`)) return
      try {
        currentTarget.querySelector(`.MarkupContent-Container`).focus()
      } catch(e) {}
    },
    [],
  )

  useEffectAsync(
    () => {
      setDisplayedTextSizes(textSizesSetting.value)
    },
    [ textSizesSetting, setDisplayedTextSizes ],
  )

  useEffectAsync(
    () => {
      setDisplayedLineSpacings(lineSpacingsSetting.value)
    },
    [ lineSpacingsSetting, setDisplayedLineSpacings ],
  )

  useEffectAsync(
    () => {
      setSelectedColor(`ALL`)
    },
    [ currentFrmtgKeyIdSetting.value ],
  )

  const adjustmentType = (
    (displayedTextSizes !== textSizesSetting.value && `textsizes`)
    || (displayedLineSpacings !== lineSpacingsSetting.value && `linespacings`)
  )
  const languageId = getTextLanguageId({ ...version, bookId })
  const textFontSize = getPassageTextSize({ textSizes: displayedTextSizes, languageId })
  const lineSpacing = getPassageLineSpacing({ lineSpacingSizes: displayedLineSpacings, languageId })
  const isRTL = isRTLText({ languageId, bookId })

  return (
    <ModuleContainer 
      ref={ref}
      noneditableViewingMode={noneditableViewingMode}
      className="Markup-ModuleContainer"
      onClick={onClick}
      {...otherProps}
    >

      {!noneditableViewingMode && modulePassages.length === 0 &&
        <NewModuleHeadingAndPassageChooser
          heading={i18n("New Markup", "", "markup")}
          module={module}
          moduleByProject={moduleByProject}
          setUpPiecesAfterPassageChange={noop}
          moduleWidth={moduleWidth}
          // newOrLearning={<NewToMarkup />}
          maxChapterSpan={3}
        />
      }

      {modulePassages.length > 0 &&
        <StyledContainerWithPassagePopper
          // ref={setContainerRef}
          $noneditableViewingMode={noneditableViewingMode}
          onTopOfAll
          selectText={inEditingMode ? selectText : undefined}
          moduleMarkups={moduleMarkups}
          formattingKeyInfo={formattingKeyInfo}
          updateFormattingKeyInfo={updateFormattingKeyInfo}
          lenses={lensesSetting.value}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          projectId={projectId}
          moduleId={id}
          // mode?, noTextSelection?
          inEditingMode={inEditingMode}
          bookId={bookId}
          versionId={versionId}
        >

          {!noneditableViewingMode && !printOrDownloadInfo &&
            <>

              <ModuleHeaderBackground />

              <ModuleTitleButton
                module={module}
                moduleByProject={moduleByProject}
                showVersionAbbr
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={goPrintOrDownloadWithBaseParams}
                goSketch={goSketch}
                goUndo={goUndo}
                width={otherProps.width}
                noPrintOrDownload
                // absolutePositionedSection={
                // }
                topSection={
                  <MarkupOptionsChangePassage
                    module={module}
                    projectId={projectId}
                  />
                }
                settingsSection={
                  <MarkupSettings
                    projectId={projectId}
                    versionId={versionId}
                    languageId={languageId}
                    displayedTextSizes={displayedTextSizes}
                    displayedLineSpacings={displayedLineSpacings}
                    setDisplayedTextSizes={setDisplayedTextSizes}
                    setDisplayedLineSpacings={setDisplayedLineSpacings}
                    {...markupSettings}
                  />
                }
                $adjustmentType={adjustmentType}
              />

              {/* {inEditingMode &&
                <DotNotesButton
                  onClick={null}
                />
              } */}

              <EditingButton
                module={module}
              />

            </>
          }

          {noneditableViewingMode === `embed` &&
            <ModuleTitleButton
              module={module}
              width={otherProps.width}
              disabled
              showVersionAbbr
            />
          }

          <MarkupContent
            module={module}
            projectId={projectId}
            inEditingMode={inEditingMode}
            noneditableViewingMode={noneditableViewingMode}
            isRTL={isRTL}
            textFontSize={textFontSize}
            lineSpacing={lineSpacing}
            goUndo={goUndo}
            goRedo={goRedo}
            goPrintOrDownload={goPrintOrDownloadWithBaseParams}
            embedFullScreen={embedFullScreen}
            markup={markup}
            markupLoading={loadingModuleMarkups || moduleSettingsLoading}
            filteredFormattingKeyInfos={filteredFormattingKeyInfos}
            setHeight={setHeight}
            {...markupSettings}
          />

          {lensesSetting.value === `ON` &&
            <MarkupKey
              currentFrmtgKeyIdSetting={currentFrmtgKeyIdSetting}
              formattingKeyInfosSetting={formattingKeyInfosSetting}
              filteredFormattingKeyInfos={filteredFormattingKeyInfos}
              inEditingMode={inEditingMode}
              noneditableViewingMode={noneditableViewingMode}
              minimized={minimized}
              printOrDownloadInfo={printOrDownloadInfo}
              projectId={projectId}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              loading={moduleSettingsLoading}
            />
          }

        </StyledContainerWithPassagePopper>
      }

      {undoComponent}

    </ModuleContainer>
  )
}

export default memo(Markup)


// const formattingKeys = [
//   {
//     id: "lkjsdf-sdfsdfsdf-sdfd",
//     name: "Commands+",
//     type: "MARKUP",
//     info: {
//       styles: [
//         {
//           markupType: "BASE-HIGHLIGHT",
//           label: "Commands",
//         },
//       ],
//       colors: [
//         {
//           color: "RED",
//           type: "Main Topic",
//           example: "Love one another",
//           value: "?",
//         },
//       ],
//       colorDefault: {
//         type: "Secondary Topic",
//       },
//       sourceId: "fkdjfjkdf-df-df-dfsfsdfsdf-d",
//     },
//     public: false,
//   }
// ]

// const moduleSettings = {
//   formattingKeyInfos: [
//     {
//       id: "sdf",  // if a custom formattingKey is deleted, this data remains since those can be undeleted
//       colors: [
//         {
//           color: "RED",
//           value: "Main Topic",
//         },
//       ],
//     },
//   ],
//   currentFrmtgKeyId,  // the page they are on
// }


/*


  modifying a key color should modify all markups on that lens to the new color
  when going from no label to label, do not select that color at all
  Editting a system lens, not making changes, then clicking Update -> a "customized" lens that is not customized
    disable the Update button if no change.

  which lenses are being used should do dialog undo

  Embed improvements
    frozen mode display issues
      problematic situations
        when the max height is exceeded
        when in full screen
      solutions?
        module button should have bg, whether disabled or not
        ??
  Shared version display
    have the key not scroll
    should have top padding matching between key and context summary


  !! EDIT L1 - final read through
  ! Dot notes
  ! Non-markup module markup
    then add this to L1
  ! Get Print and Download working
  ! Get working within Notes

  shortcut for opening and closing notes
  get ?autologin to work with #assignment=

  Bugs
    select a version heading: highlights the entire passage
  Touch refinement
    Bug: Touch selection gets the last word of previous line
    On touch: if above, system selection menu covers the highlight options
    adjusting selection doesn't work right (at least on android)

  "Revent to Default" should only be "Revert" on mobile size
  Clean up code
    eslint warnings
    put systemFormattingKeys in formatting.js
    fix "Droppable: unsupported nested scroll container detected."
  NewToMarkup
    Besides pointing to the course, should say what the markup module is
  Add Markup and Outline to /about-tools
  multiline color labels
  Force unique names?
  snackbars to reverse stuff
  restore deleted lens
  ability to duplicate a lens
  stop yellow module focus bar from flashing when clicking different places
  Public lenses
    import publicFormattingKeysQuery from '../graphql/queries/publicFormattingKeys'
    Choosing a public lens creates it as your own lens
      const notMeAddOn = user ? ` not-userId="${user.id}"` : ``
      const { publicFormattingKeys, loading } = useDataQuery({
        publicFormattingKeysQuery,
        variables: {
          query: (
            `${query}${notMeAddOn}`
            || `id:"${possiblePublicFormattingKeyIds.join(` `).replace(/"/g, ``)}"${notMeAddOn}`
          ),
        },
        skip: possiblePublicFormattingKeyIds.length === 0,
      })
  Make entire content of project searchable from projects and search bar
  Allow user to turn "Markup using lenses" off
    first requires dev of MARKUP PALETTE IN OTHER MODULES below (or at least the part without a key)
    Do not allow a key if lenses is off
  Highlighting a partial word
  "Show outline" (outline module) should be toggle or checkbox?

  Further stuff to do in undo's prepAction
    markup:
      show lenses (if mobile size)
      ? scroll to
    outline:
      show outline (if mobile size or hidden)
      ? scroll to


  MARKUP PALETTE IN OTHER MODULES (and notes passage inserts)
    Data for markup key
      a ModuleSetting (possibly more than one)
      for Notes it is contained in the data for that insert
    UI
      text selection menu - no key
        top toggle
          "Basic Markup" / "All Markup"
          basic markup is just three colors
        bottom button (if no key)
          Create a Key
            options:
              from this project (default, if exists; otherwise "new" is default)
              from another project
              new
                will automatically fill with any existing markup in this module/notes passage
              system options
                Hebrew Grammar
                Greek Grammar
                Devotional Reading
                Who and What
                Key Points and Connectors
      text selection menu - with a key
        markup key is just the styles in the key
        ends with a + button to add a new one
          the + button shows all the markup options with a "back" option
      the key
        heading: "Markup Key"
        shows all markup ever used, whether labeled or not
        shows "unnamed" for label by default
        tap a label to rename it [if editable]
        drag (long-tap first on touch) labels to reorder [if editable]
        drag (long-tap first on touch) a label out to delete it (visually indicate this will happen + undo snackbar) [if editable]
        has button at the bottom for adding a new markup style (brings up all markup to choose from) [if editable]
        has an options button [if editable]
          minimize/expand
          share
          import (with way to search keys from my other work)
          delete
        (key is always shown if it exists, whether minimized or expanded)
  TODO:
    - change "formatting key" language everywhere to something else
    - 
      


*/
const keyDownD = async params => {

  const {
    event,
    modifierKey,
    goPrintOrDownload,
  } = params

  if(!modifierKey) return

  event.preventDefault()

  // goPrintOrDownload({ action: `download` })

}

export default keyDownD
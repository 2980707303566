import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import LockIcon from '@material-ui/icons/Lock'

import { cloneObj } from '../../../utils/misc'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import { formattingColors } from '../../../utils/formatting'

import MarkupKeyLazyLoadPageViewer from './MarkupKeyLazyLoadPageViewer'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import ReorderableList from '../../common/ReorderableList'
import ColorChooser from '../../common/ColorChooser'


export const getCombinedColors = ({ formattingKey, formattingKeyInfo }) => {

  const {
    colors=[],
    colorDefault={},
  } = (formattingKey || {}).info || {}

  let unusedColors = cloneObj(colors || []).map(color => ({
    ...color,
    fixedValue: !!color.value,
    fixedColor: !!color.value || !!color.type,
  }))

  return [
    ...cloneObj((formattingKeyInfo || {}).colors || []).map(colorObj => {
      const formattingKeyColor = colors.find(clrObj => clrObj.color === colorObj.color)
      if(formattingKeyColor) {
        unusedColors = unusedColors.filter(clrObj => clrObj.color !== colorObj.color)
        return {
          ...formattingKeyColor,
          ...colorObj,
        }
      } else {
        return {
          ...colorDefault,
          ...colorObj,
        }
      }
    }),
    ...unusedColors,
  ]

}

const None = styled.div`
  height: 200px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const MarkupKeyPage = styled.div`
  padding: 0 15px;
`

const DividerLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  opacity: .5;
  margin: 10px 0;
`

const MarkupStyleLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 27px;
`

const MarkupStyle = styled.span`
  line-height: 1.8;
`

const StyledLockIcon = styled(LockIcon)`
  font-size: 12px;
  margin: 0 -4px 0 9px;
`

const MarkupColor = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 -15px;
  padding: 2px 15px;

  .MuiInputBase-input,
  .MuiInputAdornment-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  ${({ $selected, theme }) => $selected ? `` : `
    .MarkupKeyPages-HandleContainer {
      display: none;
    }
    :hover {
      background-color: ${theme.palette.grey[100]}88;
    }
  `}

  ${({ $selected, theme }) => !$selected ? `` : `
    background-color: ${theme.palette.grey[200]};
    font-weight: 700;

    .MuiInputBase-input {
      font-weight: 700;
    }

    .MuiInputAdornment-root .MuiTypography-root {
      font-weight: 300;
    }
  `}
`

const ClearCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
`

const StyledTextField = styled(TextField)`
  min-width: 0;
  flex: 1;

  .MuiInputBase-adornedStart {
    padding-left: 10px;
  }

  .MuiInputBase-input {
    padding: 5px 10px;
    font-size: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: -7px;
  cursor: default;
  user-select: none;

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 200;
    color: inherit;
  }
`

const ViewAllMarkupColors = styled(MarkupColor)`
  padding: 0 15px;
  line-height: 34px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
`

const AddMarkupColor = styled(ViewAllMarkupColors)`
  font-weight: 300;
`

const HandleContainer = styled.div`
  height: 30px;
  width: 30px;
  margin-right: -5px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 5px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  :hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const StyledReorderableList = styled(ReorderableList)`
  .ReorderableList-DeleteDroppableContainer {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
`

const StyledColorChooser = styled(ColorChooser)`
  margin: 0 -10px;
  z-index: 1;
`

const MarkupKeyPages = ({
  pageKeyIndex,
  filteredFormattingKeyInfos,
  formattingKeyInfosSetting,
  inEditingMode,
  selectedColor,
  setSelectedColor,
  projectId,
}) => {

  const { formattingKeysById } = useFormattingKeys()

  const [ goSetFormattingKeyInfos ] = useGoSetModuleSetting({
    moduleSetting: formattingKeyInfosSetting,
    projectId,
    undoRedoStack: `undo`,
  })

  const getPageByKey = useCallback(
    formattingKeyId => {
      const newFormattingKeyInfos = cloneObj(formattingKeyInfosSetting.value)
      const formattingKeyInfo = newFormattingKeyInfos.find(({ id }) => id === formattingKeyId)
      if(!formattingKeyInfo) return null

      const save = () => {
        goSetFormattingKeyInfos({
          value: newFormattingKeyInfos,
        })
      }

      formattingKeyInfo.colors = getCombinedColors({
        formattingKey: formattingKeysById[formattingKeyId],
        formattingKeyInfo,
      })

      const selectedColorObj = formattingKeyInfo.colors.find(({ color }) => color === selectedColor)
      const showDeleteDropZone = !!selectedColorObj && !selectedColorObj.fixedColor
      const { info } = formattingKeysById[formattingKeyId] || {}
      const { styles=[] } = info || {}
      const markup = []
      const stylesContent = (
        styles
          .map(({ markupType, label }, idx) => {

            const start = {
              loc: `01001001`,
              wordNumberInVerse: idx+1,
            }

            markup.push({
              start,
              end: start,
              type: markupType,
              color: `GREY`,
            })

            return (
              <MarkupStyleLine
                key={idx+1}
              >
                <MarkupStyle
                  data-word-loc={`01001001:${idx+1}`}
                >
                  {label}
                </MarkupStyle>
              </MarkupStyleLine>
            )
          })
      )

      return (
        <MarkupKeyPage>
 
          <TextContentMarkupContainer
            markup={markup}
          >
            {stylesContent}
          </TextContentMarkupContainer>

          <DividerLine />

          <ViewAllMarkupColors
            onClick={() => setSelectedColor(`ALL`)}
            $selected={selectedColor === `ALL`}
          >
            {i18n("View all colors")}
          </ViewAllMarkupColors>

          <StyledReorderableList
            list={formattingKeyInfo.colors}
            idKey="color"
            height={formattingKeyInfo.colors.length < 8 ? 72 : 40}
            showDeleteDropZone={showDeleteDropZone}
            Line={({ color, value, type, example, fixedValue, fixedColor, dragHandleProps }) => {

              let ref = { current: null }
              const colorObj = formattingKeyInfo.colors.find(colorObj => colorObj.color === color)
              const selected = selectedColor === color

              return (
                <MarkupColor
                  key={color}
                  onClick={!selected ? () => setSelectedColor(color) : null}
                  $selected={selected}
                >

                  <StyledColorChooser
                    disabled={fixedColor || formattingKeyInfo.colors.length >= 8 || !inEditingMode}
                    onChange={({ newValue }) => {
                      colorObj.color = newValue
                      save()
                      setSelectedColor(newValue)
                    }}
                    skipColors={[ ...formattingKeyInfo.colors.map(({ color }) => color), `GREY` ]}
                    color={color}
                  />

                  {inEditingMode && fixedValue && selected &&
                    <Tooltip
                      title={i18n("Value defined in lens settings")}
                    >
                      <StyledLockIcon />
                    </Tooltip>
                  }

                  <StyledTextField
                    variant="outlined"
                    placeholder={
                      !inEditingMode
                        ? i18n("Unlabeled")
                        : (
                          example
                            ? i18n("E.g. {{example}}", { example })
                            : i18n("Enter a label here")
                        )
                    }
                    defaultValue={value}
                    inputRef={ref}
                    autoFocus={!value}
                    onBlur={() => {
                      if(!ref.current) return
                      ref.current.value = ref.current.value.trim()
                      if(colorObj.value !== ref.current.value) {
                        colorObj.value = ref.current.value
                        save()
                      }
                    }}
                    onKeyDown={event => {
                      if([ `Enter`, `Escape` ].includes(event.key)) {
                        ref.current.closest(`.Markup-ModuleContainer`).querySelector(`.MarkupContent-Container`).focus()
                      }
                    }}
                    disabled={!selected || fixedValue || !inEditingMode}
                    inputProps={{
                      tabIndex: -1,
                    }}
                    InputProps={
                      type
                        ? {
                          startAdornment: (
                            <StyledInputAdornment position="start">
                              {i18n("{{category}}:", { category: type })}
                            </StyledInputAdornment>
                          ),
                        }
                        : null
                    }
                  />

                  {inEditingMode &&
                    <Tooltip
                      title={
                        fixedColor
                          ? i18n("Drag to reorder")
                          : i18n("Drag to reorder or delete")
                      }
                    >
                      <HandleContainer
                        className="MarkupKeyPages-HandleContainer"
                        {...dragHandleProps}
                        tabIndex={-1}
                      >
                        <StyledDragHandleIcon />
                      </HandleContainer>
                    </Tooltip>
                  }

                  {(!selected || !inEditingMode) && <ClearCover />}

                </MarkupColor>
              )
            }}
            onReorder={newColors => {
              formattingKeyInfo.colors = newColors
              save()
            }}
          />

          {formattingKeyInfo.colors.length < 8 && inEditingMode &&
            <AddMarkupColor
              onClick={() => {
                const color = formattingColors.filter(color => (color !== `GREY` && !formattingKeyInfo.colors.some(colorObj => colorObj.color === color)))[0]
                formattingKeyInfo.colors = [
                  ...formattingKeyInfo.colors,
                  {
                    color,
                    value: ``,
                  }
                ]
                save()
                setSelectedColor(color)
              }}
            >
              {i18n("Add a color")}
            </AddMarkupColor>
          }

        </MarkupKeyPage>
      )
    },
    [ formattingKeysById, selectedColor, setSelectedColor, goSetFormattingKeyInfos, formattingKeyInfosSetting.value, inEditingMode ],
  )

  if(filteredFormattingKeyInfos.length === 0) {
    return (
      <None>
        {i18n("You do not have any lenses set up.")}
      </None>
    )
  }

  const getPageKey = idx => (filteredFormattingKeyInfos[idx] || {}).id || null

  return (
    <MarkupKeyLazyLoadPageViewer
      pageKey={getPageKey(pageKeyIndex)}
      getPageByKey={getPageByKey}
      previousPageKey={getPageKey(pageKeyIndex - 1)}
      nextPageKey={getPageKey(pageKeyIndex + 1)}
      width={238}
    />
  )
}

export default memo(MarkupKeyPages)
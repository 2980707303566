import { memo, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DragHandleIcon from '@material-ui/icons/DragHandle'

import useRefState from '../../../hooks/useRefState'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useGoUpdateFormattingKey, { getDefaultFormattingKey } from '../../../hooks/useGoUpdateFormattingKey'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import { cloneObj, equalObjs } from '../../../utils/misc'
import { formattingColors, formattingComponentByType } from '../../../utils/formatting'

import ConfirmDialog from '../../common/ConfirmDialog'
import CustomRadioGroup from '../../common/CustomRadioGroup'
import CustomCheckbox from '../../common/CustomCheckbox'
import MarkupTypeChooser from './MarkupTypeChooser'
import ColorChooser from '../../common/ColorChooser'
import ReorderableList from '../../common/ReorderableList'
import InfoDialog from '../../common/InfoDialog'

let styleIdx = 0

const StyledConfirmDialog = styled(ConfirmDialog)`
  .MuiDialog-paper {
    width: 400px;
    max-width: calc(100vw - 50px);
    margin: 25px;
  }
`

const Style = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 -5px;
  padding: 10px 0 5px 0;
`

const Color = styled.div`
  padding: 10px 0 0;
`

const ColorValue = styled.div`
  display: flex;
  align-items: center;
`

const ColorInfo = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const StyledCustomRadioGroup = styled(CustomRadioGroup)`
  flex-direction: row;
  margin: -2px 0;

  .MuiFormControlLabel-root {
    padding: 0 11px 0 0;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    margin-left: -5px;
  }
`

const StyledCustomCheckbox = styled(CustomCheckbox)`
  padding: 0 0 0 5px;
  margin: -3px 0;

  .MuiFormControlLabel-label {
    margin-left: -5px;
  }
`

const DefColorDefinitionCheckbox = styled(StyledCustomCheckbox)`
  margin: 12px 0 0;
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`

const StyledTextField = styled(TextField)`
  flex: 2;
`

const PrefixTextField = styled(StyledTextField)`
  flex: 1;
  margin-right: 10px;
`

const NameTextField = styled(StyledTextField)`
  width: 100%;
  margin: 0 0 20px;
`

const StyledDivider = styled(Divider)`
  margin: 15px 0;
`

const DefaultColor = styled(Color)`
  padding: 12px 10px 5px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
`

const StyledColorChooser = styled(ColorChooser)`
  margin-left: -5px;
`

const Required = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: 300;
  margin-top: 15px;
`

const HandleContainer = styled.div`
  height: 40px;
  width: 40px;
  margin-right: -5px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 10px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  :hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const StyledReorderableList = styled(ReorderableList)`
  margin: -10px -10px 10px 0;
  padding-right: 10px;
`

const TitleLine = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
`

const AccountWideNote = styled.div`
  background: ${({ theme }) => theme.palette.tertiary.dark};
  border-radius: 3px;
  color: white;
  line-height: 1.4;
  font-size: 10.5px;
  padding: 0 5px;
  align-self: flex-start;
  font-weight: 400;
  margin: 2px 0 0;
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  margin: -10px -10px -10px 5px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const InfoLine = styled.div`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 16px;
`

const EditLensDialog = ({
  onCancel,
  formattingKeyInfos,
  goSetFormattingKeyInfos,
  formattingKeyId,
}) => {

  const open = !!formattingKeyId
  const formattingKeyIdRef = useRef()
  formattingKeyIdRef.current = formattingKeyId || formattingKeyIdRef.current
  formattingKeyId = formattingKeyIdRef.current

  const { formattingKeysById } = useFormattingKeys()

  let formattingKey = formattingKeysById[formattingKeyId]
  const creatingNewBlank = formattingKeyId === `new`
  const creatingNewFromSystemLens = /^system:/.test((formattingKey || {}).id || ``)
  const fromSystemLens = creatingNewFromSystemLens || /^system:/.test(((formattingKey || {}).info || {}).sourceId || ``)

  const [ goUpdateFormattingKey, goDeleteFormattingKey ] = useGoUpdateFormattingKey({
    formattingKey: creatingNewFromSystemLens ? null : formattingKey,
  })

  const [ editedFormattingKey, setEditedFormattingKey, getEditedFormattingKey ] = useRefState()
  const [ confirmDelete, toggleConfirmDelete ] = useSimpleToggle()
  const [ showInfoDialog, toggleShowInfoDialog ] = useSimpleToggle()
  const skipTypes = useEqualObjsMemo((((editedFormattingKey || {}).info || {}).styles || []).map(({ markupType }) => markupType))
  const skipColors = useEqualObjsMemo([ ...(((editedFormattingKey || {}).info || {}).colors || []).map(({ color }) => color), `GREY` ])

  const colorInfoTypeRadios = useMemo(
    () => (
      [
        {
          value: `example`,
          label: i18n("Example"),
        },
        {
          value: `value`,
          label: i18n("Fixed value"),
        },
      ]
    ),
    [],
  )

  const onConfirm = useCallback(
    () => {
      const updatedFormattingKey = cloneObj(getEditedFormattingKey())

      updatedFormattingKey.name = updatedFormattingKey.name.trim()
      const { styles=[], colors=[], colorDefault={} } = updatedFormattingKey.info
      styles.forEach(style => {
        style.label = style.label.trim()
      })
      colors.forEach(colorObj => {
        ;[ `type`, `example`, `value` ].forEach(key => {
          if(typeof colorObj[key] === `string`) {
            colorObj[key] = colorObj[key].trim()
          }
        })
      })
      if(colorDefault) {
        if(colorDefault.type) {
          colorDefault.type = colorDefault.type.trim()
        }
        if(colorDefault.example) {
          colorDefault.example = colorDefault.example.trim()
        }
      }

      if(!creatingNewFromSystemLens && formattingKey) {
        for(let key in updatedFormattingKey) {
          if(equalObjs(updatedFormattingKey[key], formattingKey[key])) {
              delete updatedFormattingKey[key]
          }
        }
      }

      if(creatingNewFromSystemLens) {
        updatedFormattingKey.info.sourceId = formattingKey.id
      }

      if(Object.values(updatedFormattingKey).length === 0) return onCancel()

      const { id } = goUpdateFormattingKey(updatedFormattingKey)

      if(creatingNewBlank) {
        goSetFormattingKeyInfos({
          value: [
            ...formattingKeyInfos,
            {
              id,
              colors: [],
            },
          ],
        })
      }

      onCancel()
    },
    [ onCancel, goUpdateFormattingKey, getEditedFormattingKey, formattingKey, creatingNewFromSystemLens, creatingNewBlank, formattingKeyInfos, goSetFormattingKeyInfos ],
  )

  const onClickAddStyle = useCallback(
    () => {
      setEditedFormattingKey({
        ...getEditedFormattingKey(),
        info: {
          ...getEditedFormattingKey().info,
          styles: [
            ...(getEditedFormattingKey().info.styles || []),
            {
              markupType: `${styleIdx++}`,
              label: "",
            },
          ],
        },
      })
    },
    [ setEditedFormattingKey, getEditedFormattingKey ],
  )

  const onClickAddColor = useCallback(
    () => {
      const color = formattingColors.filter(color => !skipColors.includes(color))[0]
      setEditedFormattingKey({
        ...getEditedFormattingKey(),
        info: {
          ...getEditedFormattingKey().info,
          colors: [
            ...(getEditedFormattingKey().info.colors || []),
            {
              color,
              example: "",
            },
          ],
        },
      })
    },
    [ setEditedFormattingKey, getEditedFormattingKey, skipColors ],
  )

  const onColorInfoRadioChange = useCallback(
    ({ target }) => {

      const colorsIdx = target.closest(`[data-colors-idx]`).getAttribute(`data-colors-idx`)
      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())

      if(target.value === `example`) {
        newEditedFormattingKey.info.colors[colorsIdx].example = newEditedFormattingKey.info.colors[colorsIdx].value
        delete newEditedFormattingKey.info.colors[colorsIdx].value
      } else {
        newEditedFormattingKey.info.colors[colorsIdx].value = newEditedFormattingKey.info.colors[colorsIdx].example
        delete newEditedFormattingKey.info.colors[colorsIdx].example
      }

      setEditedFormattingKey(newEditedFormattingKey)

    },
    [ getEditedFormattingKey, setEditedFormattingKey ],
  )

  const hasColorDefault = !!((editedFormattingKey || {}).info || {}).colorDefault
  const toggleColorDefault = useCallback(
    () => {

      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())

      delete newEditedFormattingKey.info.colorDefault
      if(!hasColorDefault) {
        newEditedFormattingKey.info.colorDefault = {
          example: "",
        }
      }

      setEditedFormattingKey(newEditedFormattingKey)

    },
    [ hasColorDefault, getEditedFormattingKey, setEditedFormattingKey ],
  )

  const onChange = useCallback(
    ({ target, keys, newValue }) => {

      if(!keys && target instanceof Element) {
        keys = target.closest(`[data-keys]`).getAttribute(`data-keys`).split(` `)
      } else if(typeof keys === `string`) {
        keys = keys.split(` `)
      }

      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())
      let obj = newEditedFormattingKey
      keys.slice(0,-1).forEach(key => {
        obj = obj[key]
      })
      if(target instanceof Element && target.closest(`[data-splice]`)) {
        obj.splice(parseInt(keys.pop(), 10), 1)
      } else if((target || {}).type === `checkbox`) {
        if(target.checked) {
          obj[keys.pop()] = ``
        } else {
          delete obj[keys.pop()]
        }
      } else {
        obj[keys.pop()] = newValue !== undefined ? newValue : target.value
      }

      setEditedFormattingKey(newEditedFormattingKey)

    },
    [ getEditedFormattingKey, setEditedFormattingKey ],
  )

  const onReorderStyles = useCallback(
    newStyles => {
      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())
      newEditedFormattingKey.info.styles = newStyles
      setEditedFormattingKey(newEditedFormattingKey)
    },
    [ getEditedFormattingKey, setEditedFormattingKey ],
  )

  const onReorderColors = useCallback(
    newColors => {
      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())
      newEditedFormattingKey.info.colors = newColors
      setEditedFormattingKey(newEditedFormattingKey)
    },
    [ getEditedFormattingKey, setEditedFormattingKey ],
  )

  const goDelete = useCallback(
    () => {
      goDeleteFormattingKey()
      toggleConfirmDelete()
      onCancel()
    },
    [ toggleConfirmDelete, goDeleteFormattingKey, onCancel ],
  )

  useLayoutEffectAsync(
    () => {
      if(formattingKeyId && open) {

        const newFormattingKey = {
          ...getDefaultFormattingKey(),
          ...cloneObj(formattingKey || {}),
        }

        delete newFormattingKey.__typename
        delete newFormattingKey.savedAt
        delete newFormattingKey.createdAt
        delete newFormattingKey.user

        if(creatingNewFromSystemLens) {
          delete newFormattingKey.id
        }

        setEditedFormattingKey(newFormattingKey)

      }
    },
    [ formattingKeyId, open ],
  )

  const commonProps = useMemo(
    () => ({
      onChange,
      variant: "outlined",
      size: "small",
    }),
    [ onChange ],
  )

  const getDragIcon = useCallback(
    dragHandleProps => (
      <Tooltip
        title={i18n("Drag to reorder or delete")}
      >
        <HandleContainer {...dragHandleProps}>
          <StyledDragHandleIcon />
        </HandleContainer>
      </Tooltip>
    ),
    [],
  )

  const StyleLine = useCallback(
    ({ markupType, label, index, dragHandleProps }) => (
      <Style>

        <MarkupTypeChooser
          markupType={markupType}
          skipTypes={skipTypes}
          onChange={onChange}
          keys={`info styles ${index} markupType`}
        />

        <StyledTextField
          value={label}
          data-keys={`info styles ${index} label`}
          label={i18n("Label")}
          {...commonProps}
        />

        {getDragIcon(dragHandleProps)}

      </Style>
    ),
    [ skipTypes, onChange, commonProps, getDragIcon ],
  )

  const ColorLine = useCallback(
    ({ color, type, example, value, index, dragHandleProps }) => (
      <Color>

        <ColorValue>

          <StyledColorChooser
            onChange={onChange}
            keys={`info colors ${index} color`}
            skipColors={skipColors}
            color={color}
          />

          {type !== undefined &&
            <PrefixTextField
              value={type}
              data-keys={`info colors ${index} type`}
              label={i18n("Prefix")}
              {...commonProps}
            />
          }

          {example !== undefined &&
            <StyledTextField
              value={example}
              data-keys={`info colors ${index} example`}
              placeholder={i18n("Optional")}
              InputProps={{
                startAdornment: <InputAdornment position="start">{i18n("E.g.")}</InputAdornment>,
              }}          
              {...commonProps}
            />
          }

          {value !== undefined &&
            <StyledTextField
              value={value}
              data-keys={`info colors ${index} value`}
              placeholder={i18n("Optional")}
              {...commonProps}
            />
          }

          {getDragIcon(dragHandleProps)}

        </ColorValue>

        <ColorInfo>

          <StyledCustomCheckbox
            checked={type !== undefined}
            data-keys={`info colors ${index} type`}
            onChange={onChange}
            label={i18n("Include prefix")}
          />

          <StyledCustomRadioGroup
            value={example !== undefined ? `example` : `value`}
            onChange={onColorInfoRadioChange}
            radios={colorInfoTypeRadios}
            data-colors-idx={index}
          />

        </ColorInfo>

      </Color>
    ),
    [ skipColors, onChange, commonProps, onColorInfoRadioChange, colorInfoTypeRadios, getDragIcon ],
  )

  if(!editedFormattingKey) return null

  const disabled = (
    !editedFormattingKey.name.trim()
    || (
      (editedFormattingKey.info.styles || []).length + (editedFormattingKey.info.colors || []).length === 0
      && !editedFormattingKey.info.colorDefault
    )
    || (editedFormattingKey.info.styles || []).some(({ markupType, label }) => (!formattingComponentByType[markupType] || !label.trim()))
    || (editedFormattingKey.info.colors || []).some(({ type }) => type === ``)
    || (
      editedFormattingKey.info.colorDefault
      && (
        (
          !(editedFormattingKey.info.colorDefault.example || ``).trim()
          && !editedFormattingKey.info.colorDefault.type
        )
        || (
          typeof editedFormattingKey.info.colorDefault.type === `string`
          && editedFormattingKey.info.colorDefault.type.trim() === ``
        )
      )
    )
  )

  return (
    <>

      <StyledConfirmDialog
        open={open}
        onCancel={onCancel}
        onConfirm={onConfirm}
        confirmButtonLabel={
          (creatingNewBlank && i18n("Create", "", "markup"))
          || i18n("Update", "", "markup")
        }
        // loading={loading}
        disabled={disabled}
        title={
          <TitleLine>
            <Title>
              {
                (creatingNewBlank && i18n("Create a New Lens", "", "markup"))
                || (fromSystemLens && i18n("Customize This System Lens", "", "markup"))
                || i18n("Edit This Lens", "", "markup")
              }
              <AccountWideNote>
                {i18n("Account-Wide Setting")}
              </AccountWideNote>
            </Title>
            <StyledIconButton onClick={toggleShowInfoDialog}>
              <InfoOutlinedIcon />
            </StyledIconButton>
            <InfoDialog
              open={showInfoDialog}
              onOkay={toggleShowInfoDialog}
              title={i18n("About Lenses")}
              explanation={
                <>
                  <InfoLine>
                    {i18n("Lenses allow you to markup a passage in a structured way by defining what different styles (and colors) mean.")}
                    {` `}
                    {i18n("The lenses you create and customize are available to you throughout the app, not just in this project and module.")}
                  </InfoLine>
                  <InfoLine>
                    {i18n("Note: Most lenses should only have style definitions.")}
                    {` `}
                    {i18n("This leaves you to use colors to associate different details within a particular passage.")}
                  </InfoLine>
                </>
              }
            />
          </TitleLine>
        }
        explanation={
          <>

            <NameTextField
              value={editedFormattingKey.name}
              data-keys={`name`}
              label={i18n("Name")}
              {...commonProps}
            />

            <StyledReorderableList
              list={editedFormattingKey.info.styles || []}
              idKey="markupType"
              Line={StyleLine}
              onReorder={onReorderStyles}
              showDeleteDropZone
            />

            {(editedFormattingKey.info.styles || []).length < 11 &&
              <Button
                onClick={onClickAddStyle}
                size="small"
                variant="contained"
                disableElevation
                fullWidth
              >
                {i18n("Add a style definition")}
              </Button>
            }

            <StyledDivider />

            <StyledReorderableList
              list={editedFormattingKey.info.colors || []}
              idKey="color"
              Line={ColorLine}
              onReorder={onReorderColors}
              showDeleteDropZone
            />

            {(editedFormattingKey.info.colors || []).length < 8 &&
              <Button
                onClick={onClickAddColor}
                size="small"
                variant="contained"
                disableElevation
                fullWidth
              >
                {i18n("Add a color definition")}
              </Button>
            }

            <DefColorDefinitionCheckbox
              checked={!!editedFormattingKey.info.colorDefault}
              onChange={toggleColorDefault}
              label={i18n("Include default color definition")}
            />

            {hasColorDefault &&
              <DefaultColor>

                <ColorValue>

                  {editedFormattingKey.info.colorDefault.type !== undefined &&
                    <PrefixTextField
                      value={editedFormattingKey.info.colorDefault.type}
                      data-keys={`info colorDefault type`}
                      label={i18n("Prefix")}
                      {...commonProps}
                    />
                  }

                  <StyledTextField
                    value={editedFormattingKey.info.colorDefault.example}
                    data-keys={`info colorDefault example`}
                    placeholder={editedFormattingKey.info.colorDefault.type !== undefined ? i18n("Optional") : null}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{i18n("E.g.")}</InputAdornment>,
                    }}
                    {...commonProps}
                  />

                </ColorValue>

                <StyledCustomCheckbox
                  value={editedFormattingKey.info.colorDefault.type !== undefined}
                  data-keys={`info colorDefault type`}
                  onChange={onChange}
                  label={i18n("Include prefix")}
                />

              </DefaultColor>
            }

            {!creatingNewBlank && !creatingNewFromSystemLens &&
              <StyledButton
                color="primary"
                onClick={toggleConfirmDelete}
              >
                {
                  fromSystemLens
                    ? i18n("Revert to Default")
                    : i18n("Delete")
                }
              </StyledButton>
            }

            <Required>
              {i18n("All fields required unless marked optional.")}
            </Required>

          </>
        }
      />

      <ConfirmDialog
        open={confirmDelete}
        onCancel={toggleConfirmDelete}
        onConfirm={goDelete}
        title={i18n("Delete Your Lens Named “{{name}}”", "", "markup", formattingKey)}
        explanation={i18n("Doing so will remove this lens from all your projects.", "", "markup")}
        doubleConfirm
      />

      
    </>
  )
}

export default memo(EditLensDialog)
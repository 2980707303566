import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
  z-index: -2;

  ${({ $includesStart, $includesEnd, $isRTL, width, height, theme, color, $darkGreyInsteadOfGrey }) => `
    box-sizing: border-box;
    margin-top: -.14em;
    margin-${$isRTL ? `right` : `left`}: ${($isRTL ? $includesEnd : $includesStart) ? `-.3em` : 0};
    width: calc(${width}px + ${$includesStart ? `.3em` : `0px`} + ${$includesEnd ? `.3em` : `0px`});
    height: calc(${height}px + .28em);
    border: .1em solid ${theme.palette.markupColors[color].line};
    border-radius: 1em;
    ${$includesStart ? `` : `
      border-left-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `};
    ${$includesEnd ? `` : `
      border-right-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};
    background-color: ${$darkGreyInsteadOfGrey ? `#FFFFFF33` : `${theme.palette.markupColors[color].arrow}22`};
  `}
`

const Circle = ({
  color,
  includesStart,
  includesEnd,
  darkGreyInsteadOfGrey,
  isRTL=false,
  ...otherProps
}) => {

  if(isRTL) {
    [ includesStart, includesEnd ] = [ includesEnd, includesStart ]
  }

  return (
    <StyledMarkupLine
      {...otherProps}
      color={color}
      $includesStart={includesStart !== false}
      $includesEnd={includesEnd !== false}
      $isRTL={isRTL}
      $darkGreyInsteadOfGrey={darkGreyInsteadOfGrey}
    />
  )
}

export default memo(Circle)
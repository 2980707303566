import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
	background-image: url('${({ theme, color }) => (
    `data:image/svg+xml;utf8,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.01 13">
        <polyline
          fill="none"
          stroke="${encodeURIComponent(theme.palette.markupColors[color].line)}"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width=".2em"
          points="40.01 11.5 30.01 1.5 20.01 11.5 10.01 1.5 0.01 11.5"
        />
      </svg>
    `.replace(/\s*\n\s*/g, ' ')
  )}');
	background-position: left bottom;
	background-size: .5em .5em;
	background-repeat: repeat-x;
  padding-bottom: .3em;
  z-index: -5;
`

const ZigZagUnderline = ({
  color,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    color={color}
  />
)


export default memo(ZigZagUnderline)
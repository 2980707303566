import useGoUpdateModulePassage from '../hooks/useGoUpdateModulePassage'
import useGoUpdateModulePiece from '../hooks/useGoUpdateModulePiece'
import useGoUpdateModuleMarkup from '../hooks/useGoUpdateModuleMarkup'
import useGoSetModuleSetting from '../hooks/useGoSetModuleSetting'
import useLayoutEffectAsync from '../hooks/useLayoutEffectAsync'

// modulePassage

const CreateModulePassage = ({ projectId, data, initData, undoRedoStack }) => {

  const [ goCreateModulePassage ] = useGoUpdateModulePassage({
    moduleId: data.moduleId,
    projectId,
    undoRedoStack,
  })

  useLayoutEffectAsync(
    () => { goCreateModulePassage(initData) },
    [],
  )

  return null
}

const UpdateModulePassage = ({ projectId, data, updateObj, undoRedoStack }) => {

  const [ goUpdateModulePassage ] = useGoUpdateModulePassage({
    modulePassage: data,
    projectId,
    moduleId: data.moduleId,
    undoRedoStack,
    isUpdate: true,
  })

  useLayoutEffectAsync(() => { goUpdateModulePassage(updateObj) }, [])

  return null
}

const DeleteModulePassage = ({ data, undoRedoStack }) => {

  const [ x, goDeleteModulePassage ] = useGoUpdateModulePassage({  // eslint-disable-line no-unused-vars
    modulePassage: data,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModulePassage() }, [])

  return null
}

// modulePiece

const CreateModulePiece = ({ projectId, onCreate, data, initData, undoRedoStack }) => {

  const [ goCreateModulePiece ] = useGoUpdateModulePiece({
    moduleId: data.moduleId,
    projectId,
    onUpdate: onCreate,
    undoRedoStack,
  })

  useLayoutEffectAsync(
    () => { goCreateModulePiece(initData) },
    [],
  )

  return null
}

const UpdateModulePiece = ({ projectId, data, updateObj, undoRedoStack }) => {

  const [ goUpdateModulePiece ] = useGoUpdateModulePiece({
    modulePiece: data,
    projectId,
    moduleId: data.moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModulePiece(updateObj) }, [])

  return null
}

const DeleteModulePiece = ({ data, undoRedoStack }) => {

  const [ x, goDeleteModulePiece ] = useGoUpdateModulePiece({  // eslint-disable-line no-unused-vars
    modulePiece: data,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModulePiece() }, [])

  return null
}

// moduleMarkup

const CreateModuleMarkup = ({ projectId, data, initData, undoRedoStack }) => {

  const [ goCreateModuleMarkup ] = useGoUpdateModuleMarkup({
    moduleId: data.moduleId,
    projectId,
    undoRedoStack,
  })

  useLayoutEffectAsync(
    () => { goCreateModuleMarkup(initData) },
    [],
  )

  return null
}

const UpdateModuleMarkup = ({ projectId, data, updateObj, undoRedoStack }) => {

  const [ goUpdateModuleMarkup ] = useGoUpdateModuleMarkup({
    moduleMarkup: data,
    projectId,
    moduleId: data.moduleId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModuleMarkup(updateObj) }, [])

  return null
}

const DeleteModuleMarkup = ({ data, undoRedoStack }) => {

  const [ x, goDeleteModuleMarkup ] = useGoUpdateModuleMarkup({  // eslint-disable-line no-unused-vars
    moduleMarkup: data,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goDeleteModuleMarkup() }, [])

  return null
}

// moduleSetting

const UpdateModuleSetting = ({ projectId, data, updateObj, undoRedoStack }) => {

  const [ goUpdateModuleSetting ] = useGoSetModuleSetting({
    moduleSetting: data,
    projectId,
    undoRedoStack,
  })

  useLayoutEffectAsync(() => { goUpdateModuleSetting(updateObj) }, [])

  return null
}

const undoComponentsByAction = {
  createModulePassage: CreateModulePassage,
  updateModulePassage: UpdateModulePassage,
  deleteModulePassage: DeleteModulePassage,
  createModulePiece: CreateModulePiece,
  updateModulePiece: UpdateModulePiece,
  deleteModulePiece: DeleteModulePiece,
  createModuleMarkup: CreateModuleMarkup,
  updateModuleMarkup: UpdateModuleMarkup,
  deleteModuleMarkup: DeleteModuleMarkup,
  updateModuleSetting: UpdateModuleSetting,
}

export default undoComponentsByAction
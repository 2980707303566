import { useMemo, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'
import { cloneObj } from '../utils/misc'

import moduleDotQuery from '../graphql/queries/moduleDot'
import moduleDotsQuery from '../graphql/queries/moduleDots'
import updateModuleDotMutation from '../graphql/mutations/updateModuleDot'
import deleteModuleDotMutation from '../graphql/mutations/deleteModuleDot'

export const getDefaultModuleDot = () => ({
  __typename: `ModuleDot`,
  content: "",
  positionInfo: null,
  modulePieceId: null,
})

const useGoUpdateModuleDot = ({
  moduleDot,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  moduleId = moduleId || moduleDot.moduleId

  const client = useApolloClient()

  const [ updateModuleDot, updateModuleDotResult ] = useMutation(updateModuleDotMutation)
  const [ deleteModuleDot, deleteModuleDotResult ] = useMutation(deleteModuleDotMutation)

  const onUpdate = useCallback(
    ({ newData }) => {
      // update moduleDots query

      const updateQuery = ({ modulePieceId, doRemove }={}) => {

        const queryParams = {
          query: moduleDotsQuery,
          variables: {
            moduleId,
            modulePieceId,
          },
        }

        const { moduleDots } = client.readQuery(queryParams) || {}

        if(moduleDots) {
          if(!doRemove && !moduleDots.some(({ id }) => id === newData.id)) {
            client.writeQuery({
              ...queryParams,
              data: {
                moduleDots: [ ...cloneObj(moduleDots), newData ].sort((a,b) => a.id < b.id ? -1 : 1),
              },
            })
          } else if(doRemove && moduleDots.some(({ id }) => id === newData.id)) {
            client.writeQuery({
              ...queryParams,
              data: {
                moduleDots: cloneObj(moduleDots).filter(({ id }) => id === moduleDot.id),
              },
            })
          }
        }

      }

      if(moduleDot) {
        if(moduleDot.modulePieceId !== newData.modulePieceId) {
          // remove it from the old modulePieceId
          updateQuery({
            modulePieceId: moduleDot.modulePieceId,
            doRemove: true,
          })
        }
      } else {
        // put it in the query without modulePieceId
        updateQuery()
      }

      // add it to the new modulePieceId
      updateQuery({ modulePieceId: newData.modulePieceId })

    },
    [ moduleDot, moduleId, client ],
  )

  const goUpdate = useGoUpdateTable({
    currentData: moduleDot || getDefaultModuleDot(),
    updateFunc: updateModuleDot,
    updateResult: updateModuleDotResult,
    deleteFunc: deleteModuleDot,
    deleteResult: deleteModuleDotResult,
    projectId,
    moduleId,
    undoRedoStack,
    onUpdate,
  })

  const toReturn = useMemo(
    () => {
      if(!moduleDot) {
        return [
          initData => {
            // Create a new moduleDot

            const newData = goUpdate[0]({
              ...initData,
              moduleId,
            })

            client.writeQuery({
              query: moduleDotQuery,
              data: {
                moduleDot: newData,
              },
              variables: {
                id: newData.id,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ moduleDot, moduleId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModuleDot
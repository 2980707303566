import { useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import styled from 'styled-components'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'

import undoComponentsByAction from '../utils/undoComponentsByAction'
import useSimpleToggle from './useSimpleToggle'
import useSetTimeout from './useSetTimeout'
import useUndoRedoShortcuts from './useUndoRedoShortcuts'
import useInstanceValue from './useInstanceValue'
import { getModifierChar } from '../utils/misc'

const FLASH_LENGTH = 300

const undoSetsByModuleId = {}
const redoSetsByModuleId = {}
let skipClearingRedoStack = false

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: none;
    background: none;
    padding: 30px;
  }
`

const UndoRedoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const UndoRedoButtons = styled.div`
  display: flex;
  gap: 20px;
`

const getBoxShadow = size => `box-shadow: 1px 1px ${size}px white, -1px -1px ${size}px white, -1px 1px ${size}px white, 1px -1px ${size}px white;`

const UndoRedoButton = styled(Button)`
  width: 100px;
  height: 100px;
  padding: 0;
  border-radius: 100px;
  background: white;
  ${getBoxShadow(30)}
  white-space: nowrap;
  overflow: hidden;

  :hover {
    background: ${({ theme }) => theme.palette.grey[100]};
    ${getBoxShadow(50)}
  }

  .MuiButton-label {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
  }

  .MuiButton-label .MuiSvgIcon-root {
    font-size: 30px;
    margin: -5px 0 -10px;
  }

`

const DoneButton = styled(Button)`
  ${getBoxShadow(10)};

  :hover {
    ${getBoxShadow(25)}
  }
`

const ShortcutCombo = styled.div`
  margin-top: -4px;
  padding: 0 3px;
  border-radius: 3px;
  font-size: 10px;
  line-height: 1.5;
  opacity: .6;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  max-width: 75px;
  overflow: hidden;
`

const FlashCover = styled.div`
  background: white;
  transition: opacity ${FLASH_LENGTH/2}ms ease-in-out;
  opacity: ${({ $show }) => $show ? 1 : 0};
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`

export const pushUndoRedoItem = ({
  type,
  action,
  data,
  ...otherParams
}) => {

  // console.log(`Setting undo item`, action, data, otherParams)
  const setsByModuleId = type === `undo` ? undoSetsByModuleId : redoSetsByModuleId
  const now = Date.now()
  let { moduleId } = data

  if(!moduleId && [ `updateModuleSetting` ].includes(action)) {
    moduleId = (data.id || ``).split(':')[0]
  }

  if(!moduleId) {
    console.error(`Bad call to pushUndoItem`, action, data, otherParams)
    return
  }

  if(type === `undo` && !skipClearingRedoStack) {
    delete redoSetsByModuleId[moduleId]
  }

  const undoRedoSets = setsByModuleId[moduleId] = setsByModuleId[moduleId] || []

  if(undoRedoSets.length > 0 && undoRedoSets.at(-1).timestamp > now - 100) {
    undoRedoSets.at(-1).items.push({
      action,
      data,
      ...otherParams,
    })
  } else {
    undoRedoSets.push({
      timestamp: now,
      items: [{
        action,
        data,
        ...otherParams,
      }],
    })
  }

}

export const getHasUndoItems = moduleId => (undoSetsByModuleId[moduleId] || []).length > 0
export const clearUndoItems = moduleId => delete undoSetsByModuleId[moduleId]

const NullComponent = () => null

const UndoComponent = ({
  undoSet={ items: [] },
  projectId,
  snackbarInfo,
  anchorEl,
  toggleShowButtons,
  goUndo,
  goRedo,
  showFlash,
  ...otherProps
 }) => (
  <>

    {undoSet.items.map((undoItem, idx) => {
      const Component = undoComponentsByAction[undoItem.action] || NullComponent

      return (
        <Component
          key={idx}
          projectId={projectId}
          undoRedoStack={undoSet.undoRedoStack}
          {...otherProps}
          {...undoItem}
        />
      )
    })}

    <FlashCover
      $show={showFlash}
    />

    <StyledPopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      marginThreshold={5}
      TransitionComponent={Fade}
      onClose={toggleShowButtons}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <UndoRedoContainer>
        <UndoRedoButtons>
          <UndoRedoButton
            variant="outlined"
            onClick={goUndo || null}
            disabled={!goUndo}
          >
            <UndoIcon />
            {i18n("Undo")}
            <ShortcutCombo>
              {`${getModifierChar()}Z`}
            </ShortcutCombo>
          </UndoRedoButton>
          <UndoRedoButton
            variant="outlined"
            onClick={goRedo || null}
            disabled={!goRedo}
          >
            <RedoIcon />
            {i18n("Redo")}
            <ShortcutCombo>
              {`${i18n("Shift")}${getModifierChar()}Z`}
            </ShortcutCombo>
          </UndoRedoButton>
        </UndoRedoButtons>
        <DoneButton
          variant="contained"
          disableElevation
          color="primary"
          onClick={toggleShowButtons}
        >
          {i18n("Done")}
        </DoneButton>
      </UndoRedoContainer>
    </StyledPopover>

  </>
)

const useGoUndo = ({
  moduleId,
  projectId,
  moduleContainerRef,
  prepAction,
  ...otherProps
 }) => {

  const [ undoSet, setUndoSet ] = useState()
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })
  const [ showButtons, toggleShowButtons ] = useSimpleToggle()
  const [ showFlash, setShowFlash ] = useState(false)
  const [ setShowFlashTimeout ] = useSetTimeout()
  const getPrepAction = useInstanceValue(prepAction)

  const goUndoOrRedo = useCallback(
    async ({ setsByModuleId, noneMsg, undoRedoStack }) => {
      if((setsByModuleId[moduleId] || []).length === 0) {
        const closeSnackbar = () => setSnackbarInfo({ ...newSnackbarInfo, open: false })
        const newSnackbarInfo = {
          open: true,
          message: noneMsg,
          onClose: closeSnackbar,
          hideButton: true,
        }
        setSnackbarInfo(newSnackbarInfo)
      } else {
        const undoSet = setsByModuleId[moduleId].pop()
        if(getPrepAction()) {
          await getPrepAction()(undoSet)
        }
        setUndoSet({ ...undoSet, undoRedoStack })
        await new Promise(resolve => setTimeout(resolve))
        setUndoSet()
        setShowFlashTimeout(() => {
          setShowFlash(true)
          setShowFlashTimeout(() => {
            setShowFlash(false)
          }, FLASH_LENGTH/2)
        })
      }
    },
    [ moduleId, setShowFlashTimeout, getPrepAction ],
  )

  const goUndo = useCallback(
    async () => {
      await goUndoOrRedo({
        setsByModuleId: undoSetsByModuleId,
        noneMsg: i18n("Cannot undo any further"),
        undoRedoStack: `redo`,  // this is the stack I should add on to
      })
      toggleShowButtons({ force: true })
    },
    [ goUndoOrRedo, toggleShowButtons ],
  )

  const goRedo = useCallback(
    async () => {
      skipClearingRedoStack = true
      await goUndoOrRedo({
        setsByModuleId: redoSetsByModuleId,
        noneMsg: i18n("Nothing further to redo"),
        undoRedoStack: `undo`,  // this is the stack I should add on to
      })
      skipClearingRedoStack = false
      toggleShowButtons({ force: true })
    },
    [ goUndoOrRedo, toggleShowButtons ],
  )

  useUndoRedoShortcuts({
    showButtons,
    toggleShowButtons,
    goUndo,
    goRedo,
  })

  return {
    goUndo,
    goRedo,
    undoComponent: (
      <UndoComponent
        undoSet={undoSet}
        projectId={projectId}
        snackbarInfo={snackbarInfo}
        anchorEl={showButtons && moduleContainerRef.current}
        toggleShowButtons={toggleShowButtons}
        goUndo={(undoSetsByModuleId[moduleId] || []).length > 0 && goUndo}
        goRedo={(redoSetsByModuleId[moduleId] || []).length > 0 && goRedo}
        showFlash={showFlash}
        {...otherProps}
      />
    ),
  }
}

export default useGoUndo



/*

STILL NEED TO DO THE FOLLOWING (when I actually have something to test it by):
  moduleDot
  useGoBulkCreateModulePieces (used in new Phrasing)
  useGoUpdateModulePieces (used in new Phrasing)

  For the following, indicate that they will need to use module history
    deleteModulePieces
    deleteModuleDots
    deleteModuleMarkups

*/